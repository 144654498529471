import React from 'react';
import './App.css';

function App() {
  return (
    <div>
      <h1 className="container-header">Sysdoc Games Showcase</h1>
      <div className="container">

          <div className="box box-1">
              <div className="cover"><img src="img/ebs.png" alt="" /></div>
              <a href="https://ebsadventure.azurewebsites.net/" target="_blank" rel="noopener noreferrer">EBS Adventure</a>
          </div>
          <div className="box box-2">
              <div className="cover"><img src="img/grow-smarter.png" alt="" /></div>
              <a href="http://growsmarter.azurewebsites.net/" target="_blank" rel="noopener noreferrer">Grow Smarter</a>
          </div>
          <div className="box box-3">
              <div className="cover"><img src="img/future-foundations.png" alt="" /></div>
              <a href="https://communityfutures.azurewebsites.net/" target="_blank" rel="noopener noreferrer">Community Futures</a>
          </div>
          <div className="box box-4">
              <div className="cover"><img src="img/global-defence.png" alt="" /></div>
              <a href="http://global-defence.sysdochost.com/" target="_blank" rel="noopener noreferrer">Global Defence</a>
          </div>
      </div>
    </div>
  );
}

export default App;
